import { Controller } from "stimulus";
import feather from 'feather-icons'

export default class extends Controller {
  static targets = [ "post_id", "kind", "media_type", "modal" ]

  page = 1;
  x_sns_url_ids = [];
  x_kind = null;
  x_from = null;
  x_custom_from = null;
  x_custom_to = null; 

  connect() {
    this.search();
  }

  search(event) {
    let history = this.getControllerByIdentifier("history")

    // SNSアカウント、絞り込み（過去●●）、並び替え（●●順）を変更した際には、pageを1に戻す
    if (
      !Array.isArray(this.x_sns_url_ids) ||
      !Array.isArray(history.sns_url_ids) ||
      this.x_sns_url_ids.length !== history.sns_url_ids.length ||
      !this.x_sns_url_ids.every((value, index) => value === history.sns_url_ids[index]) ||
      this.x_kind !== this.kind ||
      this.x_from !== history.from ||
      this.x_custom_from !== history.custom_from ||
      this.x_custom_to !== history.custom_to
    ) {
      this.page = 1;
    }

    this.x_sns_url_ids = history.sns_url_ids;
    this.x_kind = this.kind;
    this.x_from = history.from;
    this.x_custom_from = history.custom_from;
    this.x_custom_to = history.custom_to;
    
    let data = JSON.stringify({kind: this.kind, sns_url_ids: history.sns_url_ids, from: history.from, page: this.page});
    if (history.custom_from && history.custom_to) {
      data = JSON.stringify({kind: this.kind, sns_url_ids: history.sns_url_ids, from: history.from, custom_from: history.custom_from, custom_to: history.custom_to, page: this.page});
    }
    
    $("#posts").fadeOut(300);
    $("#posts_loading .spinner-border").hide();

    $.ajax({
      type: "POST",
      url: "/client/api/v1/posts/post_data?t=" + new Date().getTime(),
      contentType: 'application/json',
      data: data,
      success: (data) => {
        // もっと見るを押した場合、.media-sの空のdivがあると空白があるように見えるので、削除するようにした
        if (this.page > 1) {
          document.querySelectorAll('#posts .media-s').forEach(div => {
            if (div.innerHTML.trim() === '') {
              div.remove();
            }
          });
        } else if(this.page === 1) {
          $("#posts").empty();
        }
        this.page = data.length > 0 ? ++this.page : 1;
        
        this.append(data);
        $("#posts").fadeIn(300);

        if (data.length < 100) {
          $("#show_more_posts_button_wrapper > .show-more-button").css("display","none")
        } else {
          $("#show_more_posts_button_wrapper > .show-more-button").css("display","block")
        }

        if (event){
          this.filter_media_type();
        } else {
          // this.media_typeTargets.forEach((el) => $(el).prop("checked", false));
          this.filter_media_type();
        }
      }
    })
  }

  append(data) {

    let spaces = 6 - data.length % 6;
    // let judge_scores = data.map((e) => {return e.eng_judge_score})
    //   .sort((a,b) => {return b - a })
    //   .filter((v) => {return typeof v == "number"})

    // let is_judge_scores_all_zero = !Boolean(judge_scores.filter(Boolean).length);

    data.forEach((el,i) => {
      let html = $(this.template());
      let date = new Date(el["timestamp"]);
      let timestamp = date.getFullYear()+"/"+(date.getMonth()+1)+"/"+date.getDate();
      // let judge_icon = el.eng_judge  ? feather.icons.circle.toSvg({'stroke-width': 2, color: 'red' , width: 10, height: 10, class:"judge_icon"})   : feather.icons.x.toSvg({ 'stroke-width': 2, color: 'red' ,  width: 14, height: 14, class:"judge_icon_x"}) 
      // if (judge_scores.length > 0 && !is_judge_scores_all_zero) {
      //   let score_rate = judge_scores.indexOf(el.eng_judge_score) / judge_scores.length 
      //   if (el.eng_judge_score > 0) {
      //     if (score_rate < 0.20){
      //       judge_icon = " ◎ ";
      //     }else if(score_rate < 0.5 && score_rate >= 0.20){
      //       judge_icon = " ○ ";
      //     }else if(score_rate < 0.8 && score_rate >= 0.5){
      //       judge_icon = " △ ";
      //     }else{
      //       judge_icon = " ✕ ";
      //     }
      //   } else {
      //     judge_icon = " ✕ ";
      //   }
      // }
      $(html).attr("data-target","post.post_id")
      $(html).attr("data-media-type", el["media_product_type"])
      $(".timestamp",html).append(timestamp)
      // $(".timestamp",html).append("<span style='margin-left:8px'>[<span style='color:red'>"+ judge_icon +"</span>]</span>")
      $(".likes_count > .count",html).append(el["like_count"].toLocaleString())
      $(".eng_per > .count",html).append((el["eng_rate"] * 100).toFixed(2)+ '%');
      
      if (el["social_media_type"] == "instagram") {
        $(".saved > .count",html).append(el["saved"]);
      } else {
        $(".saved",html).remove();
      }
      if (el["media_product_type"] == 1 || el["social_media_type"] == "tiktok") {
        $(".view_count > .count",html).append(el["view_count"].toLocaleString());
      } else {
        $(".view_count",html).remove();
      }
      if (el["social_media_type"] == "tiktok") {
        $(".eng_per").css("display","none")
      } else {
        $(".eng_per").css("display","inline-table");
      }
      if (el["media_url"] !== null) {
        if (el["url"].includes("reel") && el["media_url"].includes("scontent")) {
          $(".image",html).append('<video src="'+el["media_url"]+'" value='+el["id"]+'>').attr("value",el["id"])
        } else {
          $(".image",html).append('<img src="'+el["media_url"]+'" value='+el["id"]+'>').attr("value",el["id"])
        }
      }
      else {
        $(".image",html).append('<img src="'+el["media_url"]+'" value='+el["id"]+'>').attr("value",el["id"])
      }
      // $(".image",html).append('<img src="'+el["media_url"]+'" value='+el["id"]+'>').attr("value",el["id"])
      $("#posts").append(html)
    })
    for (let i = 0 ; i < spaces; i++){
      let html = $(this.template()).empty();
      $("#posts").append(html)
    }
    
    feather.replace();
    this.filter_media_type();

  }

  template(){
    let string = "";
    string =  '<div class="media-s" data-id="" >';
    string += '<div class="timestamp"></div>';
    string += '<div class="image" data-action="click->post#post_get"></div>';
    string += '<div class="likes_count"><span class="text">いいね！</span><span class="count"></span></div>';
    string += '<div class="eng_per"><span class="text">ENG率</span><span class="count"></span></div>';
    string += '<div class="saved"><span class="text">保存数</span><span class="count"></span></div>';
    string += '<div class="view_count"><span class="text">再生数</span><span class="count"></span></div>';
    string += '</div>'
    return string;
  }

  post_get(ele){
    let id = $(ele.target).attr("value");
    let history = this.getControllerByIdentifier("history")
    $.ajax({
      type: "GET",
      url: "/client/api/v1/posts/instagrams/" + id + "?stype=" + history.main_social_media_type,
      success: (data) => {
        let el = data;
        let modal_html = $(this.modalTarget);
        let post_html = $(this.post_template());
        let date = new Date(el["timestamp"]);
        let timestamp = date.getFullYear()+"/"+(date.getMonth()+1)+"/"+date.getDate();
        if (el["caption"] != "" && el["caption"] != null){
          $(".caption",post_html).append(el["caption"].replace(/[\n\r]/g, "<br />"));
          if (el["social_media_type"] == "tiktok") {
            $(".caption",post_html).append('<a href="' + el["url"] + '" target="_blank">'+ el["url"] + '</a>');
          }
        }
        $(".name",post_html).append('<img src="'+el["profile_picture_url"]+'" /><span class="fullname">'+ el["fullname"]+'</span>');
        $(".timestamp",post_html).append(timestamp);
        $(".likes_count > .count",post_html).append(el["like_count"].toLocaleString() + "件")
        $(".eng_per > .count",post_html).append(""+(el["eng_rate"] * 100).toFixed(2) + '%');
        if (el["view_count"]) {
          $(".view_count > .count",post_html).append(el["view_count"].toLocaleString() + "回");
        } else {
          $(".view_count",post_html).remove();
        }
        $(".follows > .count",post_html).append(`${el["follows"] != null ? el["follows"].toLocaleString() : "-"}件`);
        $(".profile_activity > .count",post_html).append(`${el["profile_activity"] != null ? el["profile_activity"].toLocaleString() : "-"}件`); // リンククリック
        $(".profile_views > .count",post_html).append(`${el["profile_views"] != null ? el["profile_views"].toLocaleString() : "-"}件`);
        if (el["social_media_type"] == "instagram") {
          $(".saved > .count",post_html).append(el["saved"] + "件");
        } else {
          $(".saved",html).remove();
        }
        if (el["media_url"] !== null) {
          if (el["url"].includes("reel") && el["media_url"].includes("scontent")) {
            $(".image",post_html).append('<video src="'+el["media_url"]+'" />')
          } else {
            $(".image",post_html).append('<img src="'+el["media_url"]+'" />')
          }
        }
        else {
          $(".image",post_html).append('<img src="'+el["media_url"]+'" />')
        }
        
        $(".post_content",modal_html).empty().append(post_html)
        let modalController = this.application.getControllerForElementAndIdentifier(this.modalTarget, "modal")
        modalController.open();
      }
    })
  }

  filter_media_type(event) {
    let checked = false;
    let history = this.getControllerByIdentifier("history");
    this.media_typeTargets.forEach((el) => {
      let media_type = $(el).val();
      let posts = $("[data-media-type="+media_type+"]", $("#posts"));
      posts.css("display","block");
      if (event) {
        // if ($(event.currentTarget).val() != $(el).val()) {
        //   $(el).prop("checked", false);
        // }

      }
      if (!el.checked) {
        posts.css("display","none");
      } else {
        checked = true;
      }
    })
    // ビジネスアカウントではない場合、フィード|リールのボタンが表示されないため、必ず投稿が表示されるようにする
    if ($("#sns_posts .filter_by_media_type").css("visibility") === "hidden") {
      $("[data-media-type=0]", $("#posts")).css("display","block");
    }

    if (( event && event.target.value == 0 ) || (!event && this.media_typeTargets[0].checked) && history.main_social_media_type != 'tiktok') {
      $("#post_kind_5_label").css("display","none");
    } else {
      $("#post_kind_5_label").css("display","inline-block");
    }
    if (!checked) {
      $("#posts").children().css("display","block"); 
    }
  }

  post_template() {
    let string = "";
    string =  '<div class="media-l">';
    string += '<div class="media-left"><div class="image"></div></div>';
    string += '<div class="media-right">'
    string += '  <div class="name"></div>';
    string += '  <div class="caption narrow-scrollbar"></div>';
    string += '  <div class="likes_count"><span class="text">いいね！</span><span class="count"></span></div>';
    string += '  <div class="eng_per"><span class="text">ENG率</span><span class="count"></span></div>';
    string += '  <div class="saved"><span class="text">保存数</span><span class="count"></span></div>';
    string += '  <div class="view_count"><span class="text">再生数</span><span class="count"></span></div>';
    string += '  <div class="follows"><span class="text">フォロー数</span><span class="count"></span></div>';
    string += '  <div class="profile_activity"><span class="text">リンククリック</span><span class="count"></span></div>';
    string += '  <div class="profile_views"><span class="text">プロフィール</span><span class="count"></span></div>';
    string += '  <div class="timestamp"></div>';
    string += '  </div>'
    string += '</div>'
    return string;
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  get kind() {
    let kind = ""
    this.kindTargets.forEach((el, i) => {
      if (el.checked) {
        kind = el.value
      }
    })
    return kind
  }
  
}